#services-single-hero {
	// CSS Grid
	.c-grid {
		display: grid;
		grid-gap: $padding-md;

		grid-template-columns: 1fr;

		a {
			margin-top: 1.5rem;
			align-self: flex-start;

			@include mq(m) {
				align-self: flex-end;
			}

			@include mq(l) {
				align-self: flex-start;
			}
		}
		.info-grid {
			order: 1;
		}

		@include mq(l) {
			padding: 0;
			grid-template-columns: 1fr 1fr;

			.info-grid {
				order: unset;
			}
		}
	}

	.info-grid {
		margin: auto;
		padding: $padding;
		max-width: 325px;

		@include mq(m) {
			max-width: unset;
			margin: auto;
		}

		@include mq(l) {
			max-width: 400px;
		}

		.info-wrap {
			width: unset;
		}

		.service-icon {
			max-width: 120px;
			max-height: 150px;
			margin-bottom: 2rem;

			@include mq(m) {
				max-width: 160px;
				max-height: 200px;
			}

			@include mq(l) {
				max-width: 220px;
				max-height: 250px;
			}
		}
	}

	.service-image-wrap {
		min-height: 400px;
		width: 100%;
		background-position: center center;
		background-size: cover;

		@include mq(m) {
			margin-left: 0;
			width: 100%;
			min-height: 500px;
		}
	}
}

#service-single-content {
	.s-inner-wrap {
		width: 1000px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

#service-footer-cta {
	.footer-text {
		color: $gray;
		max-width: 800px;
		text-align: center;
		margin-bottom: 3rem;
	}

	.service-box-wrap {
		display: flex;
		flex-flow: column;
		align-content: center;
		align-items: center;
		justify-content: center;
		margin-bottom: 4rem;

		@include mq(s) {
			flex-flow: row wrap;
		}
		.service-box {
			background: $primary-color;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-content: center;
			align-items: center;
			padding: $padding;
			width: 200px;
			height: 200px;
			margin: 10px;
		}

		.service-box-image {
			height: 100px;
		}

		.service-box-title {
			@extend .fluid-body-sm;
			text-align: center;
			color: white;
			font-weight: 400;
			margin-top: 2rem;
		}
	}
}
