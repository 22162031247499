
h1,
.fluid-h1 {
	// min/max values for this element
	$max: 52px;
	$min: 32px;

	@include fluid-prop(font-size, $min, $max);
}

h2,
.fluid-h2 {
	// min/max values for this element
	$max: 42px;
	$min: 30px;

	@include fluid-prop(font-size, $min, $max);
}

h3,
.fluid-h3 {
	$max: 38px;
	$min: 28px;

	@include fluid-prop(font-size, $min, $max);
}

h4,
.fluid-h4 {
	$max: 34px;
	$min: 26px;

	@include fluid-prop(font-size, $min, $max);
}

h5,
.fluid-h5 {
	$max: 28px;
	$min: 24px;

	@include fluid-prop(font-size, $min, $max);
}

h6,
.fluid-h6 {
	$max: 24px;
	$min: 18px;

	@include fluid-prop(font-size, $min, $max);
}

html,
.fluid-body {
	$max: 20px;
	$min: 18px;

	@include fluid-prop(font-size, $min, $max);
}

.fluid-body-sm {
	$max: 16px;
	$min: 14px;

	@include fluid-prop(font-size, $min, $max);
}
