.dentist-single {
	.s-inner-wrap {
		padding-top: 50px;
	}
	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 60px;
		grid-row-gap: 40px;

		.info-item {
			order: 1;
		}

		@include mq(m) {
			grid-template-columns: 3fr 2fr;

			.info-item {
				order: 0;
			}
		}

		@include mq(l) {
			grid-template-columns: 4fr 3fr;
		}
	}

	.dentist-bio {
		margin-bottom: 2rem;
	}

	.dentist-image {
		width: 250px;

		@include mq(m) {
			width: auto;
		}
	}

	.hide-m {
		display: block;

		@include mq(m) {
			display: none;
		}
	}

	.show-m {
		display: none;

		@include mq(m) {
			display: block;
		}
	}
}

.location-wrap {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-content: center;

}

.dentist-location-card {
	display: flex;
	flex-flow: column;
	box-shadow: 2px 2px 15px rgba($black, .3);
	padding: 1rem 1.5rem;
	margin: 1rem;
	width: 350px;
	max-width: 100%;
	height: auto;

	.fancy-btn {
		margin-top: auto;
	}

	.phone-number {
		color: $secondary-color;
		font-weight: 600;
	}

	.address {
		margin-top: 0;
	}

	.hours-wrap {
		h5 {
			margin-bottom: 0;
		}
		p {
			margin-top: 0;
		}
	}

	.dentist-image {
		margin-bottom: 1.5rem;
	}
}
