/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/

a {
	color: $primary-color;
	transition: color .3s ease;
	text-decoration: none;
	font-weight: 400;

	&:hover, &:focus {
		color: $primary-color-dark;
	}

	&:active {
		color: darken($primary-color-dark, 10%);
	}
}
