// Hero
#services-hero {
	display: flex;
	flex-direction: row;
	position: relative;
	max-width: 100%;
	flex-wrap: wrap;
	@include breakpoint(large, up) {
		flex-wrap: nowrap;
	}

	@include breakpoint(large, down) {
		margin: 0;
	}

	a {
		margin-top: 1.5rem;
		align-self: center;

		@include mq(m) {
			margin-top: 0;
			align-self: center;
		}

		@include mq(l) {
			align-self: flex-start;
		}
	}
	.info-grid {
		padding: var(--fluid-padding);
		width: 100%;
		order: 2;
		justify-content: center;
		align-items: center;
		align-content: center;

		@include mq(1023px, down) {
			margin: 1rem auto 0;
			div.info-wrap {
				text-align: center;
				max-width: 330px !important;
				width: 100%;
			}
		}

		@include mq(l, up) {
			order: 1;
			margin-left: calc(-50% + 214px);
			transform: translateX(-50%);
			left: 50%;
			position: relative;
			padding-bottom: 9%;
			max-width: 430px;
		}
		@include breakpoint(1399px, up) {
			margin-left: -487px;
			padding-bottom: 12%;
		}
	}

	.service-image-wrap {
		position: relative;
		right: 0;
		top: 0;
		width: 100%;
		position: relative;
		order: 1;
		max-width: 100%;
		margin-top: 1rem;

		img {
			width: 100%;
		}
		@include breakpoint(large) {
			max-width: 1106px;
			width: calc(53% - 1rem);
			position: absolute;
			order: 2;
		}
	}
}

#services-repeater {
	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: $padding-md;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
			max-width: 80%;
			margin: 0 auto;
		}

		@include mq(l) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	.service-card {
		display: flex;
		flex-flow: column;
		padding: 1rem;
		background-color: $white;
		font-weight: 500;
		box-shadow: 4px 4px 15px rgba($black, 0.4);
		max-width: 400px;
		margin: 0 auto;

		.service-name {
			font-weight: 600;
			color: $secondary-color;
			padding-top: 0.5rem;
			padding-bottom: 1rem;
			text-align: center;
			margin-top: auto;
		}

		.service-image {
			width: 30%;
			max-height: 100px;
			margin: 1rem auto;

			@include mq(m) {
				width: 40%;
				min-width: 175px;
				max-height: 100px;
			}

			@include mq(l) {
				width: 50%;

				max-height: 150px;
			}
		}

		.service-desc {
			@extend .fluid-body-sm;
			font-weight: 400;
			margin-bottom: 2rem;
		}

		.fancy-btn {
			margin-top: auto;
		}
	}
}
