/**
 * General typography utilities
 **/

@mixin font-face-setter() {
	@if ($font-face-is-local) {
		@each $map in $font-faces {
			@font-face {
				font-family: map-get($map, "font-family");
				font-style: map-get($map, "font-style");
				font-weight: map-get($map, "font-weight");
				font-display: swap;
				src: local(map-get($map, "font-family")), url(map-get($map, "src")) format(map-get($map, "format"));
			}
		}
	} @else {

	}

}

@mixin general-typography-setter() {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $header-font-weight;
		line-height: $header-line-height;
		margin-bottom: $header-margin-bottom;
		color: $header-color;
	}

	html {
		font-weight: $body-font-weight;
	}

	p {
		margin-bottom: $paragraph-margin-bottom;
	}
}

/**
 * --- Fluid Typography ---
 *
 * Creates fonts that will scale with the width of the screen using the function below
 * Works by using viewport width and some fancy linear algorithm that works with css calc!
 * Even IE11 works with this 🙌
 *
 * TODO Make this easier to edit
 **/
@mixin fluid-typography() {
	h1,
	.fluid-h1 {
		// min/max values for this element
		$max: 52px;
		$min: 32px;

		@include fluid-prop(font-size, $min, $max);
	}

	h2,
	.fluid-h2 {
		// min/max values for this element
		$max: 42px;
		$min: 30px;

		@include fluid-prop(font-size, $min, $max);
	}

	h3,
	.fluid-h3 {
		$max: 38px;
		$min: 28px;

		@include fluid-prop(font-size, $min, $max);
	}

	h4,
	.fluid-h4 {
		$max: 34px;
		$min: 26px;

		@include fluid-prop(font-size, $min, $max);
	}

	h5,
	.fluid-h5 {
		$max: 28px;
		$min: 24px;

		@include fluid-prop(font-size, $min, $max);
	}

	h6,
	.fluid-h6 {
		$max: 24px;
		$min: 18px;

		@include fluid-prop(font-size, $min, $max);
	}

	html,
	.fluid-body {
		$max: 20px;
		$min: 18px;

		@include fluid-prop(font-size, $min, $max);
	}

	.fluid-body-sm {
		$max: 16px;
		$min: 14px;

		@include fluid-prop(font-size, $min, $max);
	}
}
/** END --- Fluid Typography --- END **/

@mixin font-light {
	font-weight: 300;
}
@mixin font-regular {
	font-weight: 400;
}
@mixin font-medium {
	font-weight: 500;
}
@mixin font-semi-bold {
	font-weight: 600;
}
@mixin font-bold {
	font-weight: 700;
}
