/**
 * Better Menu Rules!
 *
 * 1. Must be css forward only the bare minimum should be done in JS
 * 2. No opinionated styling just enough to make it work
 * 3. Anything done in JS should not cause flashing
 * 4. All JS functionality must be well documented
 * 5. Flex should be used where possible for easily adjusting layout/design
 * 6. Aria labels are NOT necessary on nav items (Foundation nav does this), This is actually discouraged by screenreaders
 * 7. visibility css should be used correctly to help out screenreaders
 **/

/****************************************
 *           BASE COMPONENT             *
 *--------------------------------------*
 * All general nav styling that happens *
 * on either type of menu is styled     *
 * here. This prevents CSS Flash when   *
 * a responsive menu changes style and  *
 * makes it easier to see the inner     *
 * workings between the two menus :)    *
 ****************************************/
[data-better-menu] {
	.menu {
		display: flex;
		ul {
			display: flex;
			flex-flow: row wrap;

			/*******************************
	     * Main nav LI item styling    *
	     *******************************/
			li.menu-item {
				position: relative;
				display: flex;
				flex-flow: column nowrap;

				padding-top: 0.5rem;
				padding-bottom: 0.5rem;

				/****************************
				 * MAIN Anchor Link Styling *
				 ****************************/
				a {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: center;
					padding: 0.5rem;
					color: white;
					font-weight: 500;

					&:hover {
						color: $secondary-color;
					}
				}

				// When the menu item is active this class will be enabled
				&.is-active {
					// Its important to only target the first anchor so it doesn't apply to the children anchors
					> a {
						background: unset; // foundation adds this rule added just to make sure it resets
						color: $secondary-color;
					}
				}
		}

		}

		/************************
		 *    Dropdown arrow    *
		 ************************/
		.menu-item-has-children {

			// Main menu anchor
			> a {
				position: relative;
				display: flex;

				// the actual arrow is an after element to prevent modifying the DOM and causing JS flash on load :)
				&::after {
					//content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' class='svg-triangle'><polygon points='0,0 15,0 5,15'/></svg>");
					content: "";
					margin-left: 5px;
					width: 0;
					height: 0;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 8px solid currentColor;
					transition: all 0.5s ease, color 0.3s ease;
					align-self: center;
				}
				/*** END DROPDOWN ARROW ***/

				ul.sub-menu {
					visibility: hidden;
				}
			}
		}
	}
}

/****************************************
 *       HORIZONTAL NAV COMPONENT       *
 *--------------------------------------*
 * Works purely on CSS desktop nav can  *
 * be freely adjusted without worrying  *
 * about any JS functionality!          *
 ****************************************/
[data-better-menu="horizontal"] {
	ul.menu {
		display: flex;
		flex-flow: row nowrap;
		list-style: none;
		z-index: 150;

		/*****************
			 * DROPDOWN MENU *
			 *****************/
		li.menu-item-has-children {
			position: relative;
			/***************************
				 * DROPDOWN MENU ACTIVATOR *
				 ***************************/
			&:hover,
			&:focus,
			&:active {
				// Dropdown arrow hover effect
				> a {
					&::after {
						transform: scale(1.2) rotate(360deg);
					}
				}

				// dropdown menu effect
				> ul.sub-menu {
					//opacity: 1;
					max-height: 450px; // CSS sucks and doesn't work with height: auto for transitions.......
					visibility: visible;
					background-color: $black;
				}
			}

			/************************************
				 * HORIZONTAL DROPDOWN MENU STYLING *
				 ************************************/
			> ul.sub-menu {
				position: absolute;
				margin-left: unset; // reset foundation rule
				display: flex;
				flex-flow: column nowrap;
				width: max-content;
				max-width: 250px;
				background: transparent;
				border: 3px solid transparent;
				z-index: 200;
				// Important to make sure the box flows just under the menu item
				top: calc(100% - 1px);
				right: 0; // Change this to left if you have a menu that makes more sense to be left aligned

				// !! Important hides the sub-menu !! //
				//opacity: 0;
				height: auto;
				visibility: hidden;
				max-height: 0;
				overflow: hidden;
				transition: all 0.5s ease;
				transition-delay: 0.1s;
				// END hiding logic

				// Interior anchor links usually need slightly different styles
				li {
					text-align: left;
					align-self: flex-start; // Options here can be flex-start/end and center for easy text alignment text-align should also be adjusted
					flex-shrink: 0;
					max-width: 250px;
				}
			}
		}
	}
}

/****************************************
 *       Vertical Nav Component         *
 *--------------------------------------*
 * Mostly CSS accordion type nav menu   *
 * Can be easily adjusted as long as    *
 * the "data-BM-menu-is-open" is used   *
 * and care is taken when working with  *
 * the dropdown arrow it must be at the *
 * right edge of the anchor!            *
 ****************************************/
[data-better-menu="vertical"] {
	width: 100%;
	max-width: 100%;
	ul.menu {
		display: flex;
		flex-flow: column nowrap; // To note this is what makes the menu go vertical
		list-style: none;
		z-index: 150;
		width: fit-content; // What a beautiful property in flex
		max-width: 100%;
		margin: 0 auto;
		li.menu-item {
			align-self: flex-start; // This aligns the menu item in the main menu
		}

		li.menu-item-has-children {
			// Very important that the anchors width is set to fit-content OR 100% and justify-content gets set to space between!
			// The JS handler expects the arrow to be at the edge of the div!
			> a {
				width: fit-content;
				justify-content: space-between;
				align-self: flex-start;
			}

			/***************************
	         * DROPDOWN MENU ACTIVATOR *
	         ***************************/
			&[data-bm-dropdown-is-open="true"] {
				// Dropdown arrow hover effect
				> a {
					&::after,
					&::before {
						transform: scale(1.2) rotate(360deg);
					}
				}

				// dropdown menu effect
				> ul.sub-menu {
					//opacity: 1;
					margin-top: 10px;
					max-height: 350px; // CSS sucks and doesn't work with height: auto for transitions.......
					visibility: visible;
				}
			}

			> ul.sub-menu {
				margin-left: unset; // reset foundation rule
				display: flex;
				flex-flow: column nowrap;
				width: max-content;
				max-width: 250px;
				margin-top: 0;

				// !! Important hides the sub-menu !! //
				//opacity: 0;
				height: auto;
				max-height: 0;
				overflow: hidden;
				visibility: hidden;
				transition: all 0.5s ease;
				transition-delay: 0.1s;
				// END hiding logic

				// Interior anchor links usually need slightly different styles
				li {
					text-align: left;
					align-self: flex-end; // Options here can be flex-start/end and center for easy text alignment text-align should also be adjusted
					flex-shrink: 0;
					max-width: 250px;
				}
			}
		}
	}

	/*
	 * Aligns the dropdown arrow to the left
	 */
	&[data-BM-align-arrow="left"] {
		li.menu-item-has-children {
			> a {
				// For a left aligned arrow the before element will be used instead
				&::before {
					//content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' class='svg-triangle'><polygon points='0,0 15,0 5,15'/></svg>");
					content: "";
					margin-right: 5px;
					width: 0;
					height: 0;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 8px solid currentColor;
					transition: all 0.5s ease-in-out;
				}

				// Force the after arrow to hide
				&::after {
					content: unset !important;
				}
			}
		}
	}
}

/*
 * Think of this as a "standard" state the JS handler will quickly overwrite these styles.
 * If any nav flashing occurs it can usually be fixed here
 */
[data-better-menu="responsive"] {
	ul.sub-menu {
		display: none;
		opacity: 0;
		height: 0;
		max-height: 0;
	}
}

/**
 * Mobile menu popup section
 **/

[data-bm-toggle],
[data-bm-popup-close] {
	cursor: pointer;
}

div[data-bm-popup] {
	display: flex;
	flex-flow: column nowrap;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $gray;
	transition: all 0.3s ease;

	.menu {
		ul {
			flex-flow: column;
		}
	}

	a {
		align-self: center;
		padding: .4rem .2rem;
	}

	@include breakpoint(medium) {
		left: unset;

		a {
			align-self: flex-start;
		}
	}

	// Default hidden state
	transform: translateX(100%);
	opacity: 0;

	.popup-inner-wrap {
		display: flex;
		flex-flow: column nowrap;
		padding: 4rem 2rem;
		width: 275px;
		max-width: 100%;
		margin: 0 auto;
	}

	&[data-bm-popup-is-open="true"] {
		transform: translateX(0);
		opacity: 1;
	}

	&[data-bm-popup-is-open="false"] {
		// It's not suggested to really do anything in this state~
		// This will override the default transform state
	}
}
