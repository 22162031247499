/**
 * --- Font-face setters ---
 * Supports Local and external font foundries
 * Prefer using .woff files when using local fonts
 **/
// If using local fonts set to true
$font-face-is-local: false;
$font-face-ext-link: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap";



@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");


// Settings for font-face
$header-font-name: "Poppins";
$body-font-name: "Poppins";
$header-font-family: $header-font-name, -apple-system, "Ubuntu", sans-serif;
$body-font-family: $body-font-name, -apple-system, "Ubuntu", sans-serif;

// Sets the font faces that will be used in the project
$font-faces: (
  (
    "font-family": $header-font-family,
    "font-weight": 500,
    "font-style": normal,
    "src": "../fonts/helvetica-bold.woff",
    "format": "woff",
  ),
  (
    "font-family": $header-font-family,
    "font-weight": 400,
    "font-style": normal,
    "src": "../fonts/helvetica-regular.woff",
  ),
  (
    "font-family": $header-font-family,
    "font-weight": 400,
    "font-style": normal,
    "src": "../fonts/helvetica-light.woff",
    "format": "woff",
  )
);

/** --- General typography settings --- **/
$header-line-height: 1.2;
$header-margin-bottom: 0.5rem;
$header-font-weight: bold;
$header-color: $primary-color;

$body-font-weight: 300;
$paragraph-margin-bottom: 1rem;


* {
	font-family: $header-font-family;
	color: $gray;
	line-height: 1.4;
}
