// Hero
#hero {
	// CSS Grid
	.c-grid {
		display: grid;
		grid-gap: $padding-md;

		grid-template-columns: 1fr;
		padding: $padding;
		@include mq(l) {
			padding: 0;
			grid-template-columns: 3fr 4fr;
		}
	}

	.info-grid {
		margin: auto;
		padding: $padding;
	}

	.hero-image-wrap {
		position: relative;
		display: flex;
		align-content: flex-end;
		margin-left: -$padding;
		padding-bottom: 60.25%;
		width: calc(100% + #{$padding * 2});

		.dentists-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: bottom;
			align-self: flex-end;
			opacity: 0;
			transition: opacity 0.75s ease;

			&[data-is-active="true"] {
				opacity: 1;
			}
		}

		@include mq(m) {
			margin-left: 0;
			width: 100%;

			.dentists-image {
				margin-left: 40px;
			}
		}

		@include mq(l) {
			margin-left: 80px;
			.dentists-image {
				margin-left: -80px;
			}
		}
	}
}

#services {
	.c-grid {
		display: grid;

		grid-template-columns: 1fr;
		grid-gap: $padding-md;
		grid-row-gap: 60px;

		@include mq(l) {
			grid-template-columns: 1fr 1fr;
			grid-gap: $padding-lg * 2;
		}

		.grid-item {
			display: flex;
		}
		.info-grid {
			margin: auto;
		}

		.services-wrap {
			order: 1;

			@include mq(m) {
				order: 0;
			}
		}
	}

	.services-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 5px;

		max-width: 400px;
		margin: 0 auto;

		.service-box:first-child {
			grid-area: 2 / 1 / 4 / 2;
		}
		.service-box:nth-child(2) {
			grid-area: 4 / 1 / 6 / 2;
		}
		.service-box:nth-child(3) {
			grid-area: 6 / 1 / 8 / 2;
		}
		.service-box:nth-child(4) {
			grid-area: 8 / 1 / 10 / 2;
		}
		.service-box:nth-child(5) {
			grid-area: 1 / 2 / 3 / 3;
		}
		.service-box:nth-child(6) {
			grid-area: 3 / 2 / 5 / 3;
		}
		.service-box:nth-child(7) {
			grid-area: 5 / 2 / 7 / 3;
		}
		.service-box:last-child {
			grid-area: 7 / 2 / 9 / 3;
		}

		@include mq(m) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(7, 1fr);
			max-width: 600px;

			.service-box:first-child {
				grid-area: 3 / 1 / 5 / 2;
			}
			.service-box:nth-child(2) {
				grid-area: 5 / 1 / 7 / 2;
			}
			.service-box:nth-child(3) {
				grid-area: 2 / 2 / 4 / 3;
			}
			.service-box:nth-child(4) {
				grid-area: 4 / 2 / 6 / 3;
			}
			.service-box:nth-child(5) {
				grid-area: 6 / 2 / 8 / 3;
			}
			.service-box:nth-child(6) {
				grid-area: 1 / 3 / 3 / 4;
			}
			.service-box:nth-child(7) {
				grid-area: 3 / 3 / 5 / 4;
			}
			.service-box:last-child {
				grid-area: 5 / 3 / 7 / 4;
			}
		}
	}

	.service-box {
		position: relative;
		padding-bottom: 100%;
		background: $light-gray;
		transition: background 0.3s ease;

		&:hover,
		&:focus {
			background: darken($light-gray, 5%);

			.service-box-image {
				transform: scale(1.15);
			}
		}

		.service-box-inner-wrap {
			display: flex;
			flex-flow: column;
			justify-content: space-around;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0.5rem;
		}

		.service-box-image {
			height: auto;
			max-height: 60%;
			width: auto;
			max-width: 80%;

			margin: 0.5rem auto;
			flex-shrink: 1;
			transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
		}

		.service-box-title {
			@extend .fluid-body-sm;
			color: $primary-color;
			font-weight: 500;
			text-align: center;
			line-height: 1.2;
			flex-shrink: 0;
		}
	}
}

#patients {
	position: relative;
	padding: 40px 0;

	.bg-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		box-shadow: inset 0 0 0 2000px rgba($white, 0.6);
		filter: blur(5px);
		z-index: 0;
		background-position: 50% 50%;
		background-size: cover;
	}

	.patient-title {
		margin-bottom: 2rem;
		text-align: center;
	}

	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;

		width: 100%;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
			max-width: 600px;
		}

		@include mq(l) {
			grid-template-columns: repeat(4, 1fr);
			max-width: 1000px;
		}
	}

	.patient-box {
		position: relative;

		background: $primary-color;
		transition: background 0.3s ease;

		@include mq(m) {
			padding-bottom: 100%;
		}

		&:hover,
		&:focus {
			background: lighten($primary-color, 10%);

			.patient-box-image {
				transform: rotate(15deg);
			}
		}

		.patient-box-inner-wrap {
			display: flex;
			flex-flow: row;
			justify-content: flex-start;
			padding: 0.5rem;

			@include mq(m) {
				flex-flow: column;
				justify-content: space-around;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.patient-box-image {
			height: 2.5rem;
			width: 6rem;
			flex-shrink: 1;
			align-content: center;
			transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

			@include mq(m) {
				height: auto;
				max-height: 50%;
				width: auto;
				max-width: 60%;
				margin: 0.5rem auto;
			}
		}

		.patient-box-title {
			@extend .fluid-body-sm;
			display: flex;
			align-items: center;
			color: $white;
			font-weight: 500;
			text-align: center;
			line-height: 1.2;
			flex-shrink: 0;

			@include mq(m) {
				margin: 0 auto;
			}
		}
	}
}

#dentists {
	.dentist-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-gap: 5px;
		max-width: 400px;
		margin: 0 auto;

		.grid-item:first-child {
			grid-area: 3 / 1 / 4 / 3;
		}
		.grid-item:nth-child(2) {
			grid-area: 1 / 1 / 2 / 3;
		}
		.grid-item:nth-child(3) {
			grid-area: 2 / 1 / 3 / 2;
		}
		.grid-item:nth-child(4) {
			grid-area: 2 / 2 / 3 / 3;
		}
		.grid-item:nth-child(5) {
			display: none;
		}
		.grid-item:nth-child(6) {
			display: none;
		}
		.grid-item:nth-child(7) {
			display: none;
		}
		.grid-item:nth-child(8) {
			display: none;
		}
		.grid-item:nth-child(9) {
			display: none;
		}
		.grid-item:nth-child(10) {
			display: none;
		}
		.grid-item:nth-child(11) {
			display: none;
		}
		.grid-item:last-child {
			display: none;
		}

		@include mq(m) {
			max-width: 100%;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr 1fr;
			.grid-item:first-child {
				grid-area: 2 / 2 / 3 / 4;
			}
			.grid-item:nth-child(2) {
				grid-area: 1 / 1 / 2 / 3;
			}
			.grid-item:nth-child(3) {
				grid-area: 1 / 3 / 2 / 4;
			}
			.grid-item:nth-child(4) {
				grid-area: 1 / 4 / 2 / 5;
			}
			.grid-item:nth-child(5) {
				grid-area: 2 / 1 / 3 / 2;
				display: flex;
			}
			.grid-item:nth-child(6) {
				grid-area: 2 / 4 / 3 / 5;
				display: flex;
			}
		}

		@include mq(l) {
			grid-template-columns: repeat(7, 1fr);

			.grid-item:first-child {
				grid-area: 2 / 3 / 2 / 6;
			}
			.grid-item:nth-child(2) {
				grid-area: 1 / 1 / 1 / 1;
			}
			.grid-item:nth-child(3) {
				grid-area: 1 / 2 / 1 / 2;
			}
			.grid-item:nth-child(4) {
				grid-area: 1 / 3 / 1 / 3;
			}
			.grid-item:nth-child(5) {
				grid-area: 1 / 4 / 1 / 4;
			}
			.grid-item:nth-child(6) {
				grid-area: 1 / 5 / 1 / 5;
			}
			.grid-item:nth-child(7) {
				grid-area: 1 / 6 / 1 / 6;
				display: flex;
			}
			.grid-item:nth-child(8) {
				grid-area: 1 / 7 / 1 / 7;
				display: flex;
			}
			.grid-item:nth-child(9) {
				grid-area: 2 / 1 / 2 / 1;
				display: flex;
			}
			.grid-item:nth-child(10) {
				grid-area: 2 / 2 / 2 / 2;
				display: flex;
			}
			.grid-item:nth-child(11) {
				grid-area: 2 / 6 / 2 / 6;
				display: flex;
			}
			.grid-item:last-child {
				grid-area: 2 / 7 / 2 / 7;
				display: flex;
			}
		}
	}

	.grid-item {
		position: relative;
		display: flex;
	}

	.dentist-grid-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		height: 275px;
		object-position: center center;

		@include mq(m) {
			height: 300px;
		}

		@include mq(l) {
			height: 350px;
		}

		@include mq(xl) {
			height: 420px;
		}
	}

	.info-item {
		display: flex;

		.info-inner-wrap {
			padding: $padding;
			max-width: 20rem;
			margin: 0 auto;

			.info-sub-text {
				margin: 1rem 0;
			}
		}
	}
}

#testimonials {
	background-color: $light-gray;

	.s-inner-wrap {
		padding-left: 0;
		padding-right: 0;
	}

	.testimonials-title {
		padding: $padding;
		margin-bottom: 2rem;
	}

	.testimonials-wrapper {
		width: 100%;
		max-width: 100%;
	}
	.testimonials-slider {
		@include mq(m) {
			padding: 40px;
		}

		--swiper-navigation-color: #{$primary-color};

		.swiper-slide {
			display: flex;
			align-self: stretch;
			height: auto;
		}
		.testimonial-card {
			position: relative;
			display: flex;
			flex-flow: column;
			align-self: stretch;
			background-color: $primary-color;
			color: white;
			padding: 20px;
			max-width: 350px;
			width: 100%;
			margin: 0 auto;
			height: auto;

			.testimonial-text {
				@extend .fluid-body-sm;
				color: white;
				margin: 45px 0;

				p {
					color: white;
				}
			}

			.testimonial-name {
				margin-top: auto;
				padding-right: 45px;
				font-weight: 500;
				color: white;
			}

			.quote-start {
				position: absolute;
				top: $padding;
				left: $padding;
				width: 40px;
			}

			.quote-end {
				position: absolute;
				right: $padding;
				bottom: $padding;
				width: 40px;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			display: block;
			background: $white;
			padding: 10px;
			height: auto;
			width: auto;

		}

		.swiper-button-next {
			right: 0;
		}

		.swiper-button-prev {
			left: 0;
		}
	}
}

#insurance {
	.insurance-title {
		text-align: center;
	}

	.insurance-slider-wrapper {
		width: 100%;
		margin-top: 2rem;

		.swiper-slide {
			display: flex;
			height: 100px;
			align-items: center;
			align-content: center;
			justify-content: center;
			img {
				max-height: 100%;
				width: auto;
				align-self: center;
				filter: saturate(0);
				opacity: 0.6;
			}
		}

		--swiper-navigation-color: #{$secondary-color};
	}
}

// Hero
#locations {
	// CSS Grid
	.c-grid {
		display: grid;
		grid-gap: $padding-md;

		grid-template-columns: 1fr;

		.locations-map-wrap {
			order: 1;
		}

		@include mq(l) {
			padding: 0;
			grid-template-columns: 4fr 3fr;

			.locations-map-wrap {
				order: unset;
			}
		}
	}

	.info-grid {
		margin: auto;
		padding: $padding;
	}

	.locations-map-wrap {
		display: flex;
		align-content: flex-end;
		position: relative;
		min-height: 400px;

		#mapContainer {
			position: absolute !important;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}

		@include mq(m) {
			margin-left: 0;
			width: 100%;
			min-height: 500px;
		}
	}
}

.info-grid {
	display: flex;
	flex-flow: column nowrap;
	max-width: 400px;

	div {
		@include mq(m) {
			width: 50%;
		}

		@include mq(l) {
			width: auto;
		}
	}

	.info-wrap {
		.info-sub-text {
			@extend .fluid-h6;

			margin: 2rem 0;
			width: auto;

			@include mq(m) {
				margin-bottom: 0;
			}

			@include mq(l) {
				margin-bottom: 2rem;
			}
		}
	}

	.info-buttons {
		display: flex;
		flex-flow: column nowrap;

		a {
			margin-bottom: 0.4rem;
			align-self: center;
		}
		@include mq(m) {
			align-self: flex-end;
		}

		@include mq(l) {
			align-self: flex-start;

			a {
				align-self: flex-start;
			}
		}
	}

	@include mq(m) {
		flex-flow: row nowrap;
		max-width: 100%;
	}

	@include mq(l) {
		flex-flow: column nowrap;
		max-width: 24rem;
	}
}

.swiper-wrapper {
	height: auto;

	.swiper-slide {
		align-self: stretch;
	}
}
