.header-nav {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	z-index: 1000;

	.header-inner-wrap {
		display: flex;
		flex-flow: row nowrap;
		width: 100%;
		max-width: $global-width;
		margin: 0 auto;
		padding: 10px $padding;
	}

	.header-grid {
		display: flex;
		flex-flow: row nowrap;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
}

// specific styles
.header-nav {
	.logo-container {
		width: 200px;

		img {
			width: 100%;
			height: auto;
		}
	}

	// general nav styles
	nav[data-better-menu] {
		li {
			display: flex;
		}
		a {
			@extend .fluid-body-sm;
			color: #404040;
			font-weight: 500 !important;
			transition: color 0.3s ease;
			text-decoration: none;
			padding: 0.4rem 0.2rem;

			@include mq(l) {
				padding: 0.2rem 0.4rem;
			}

			&:hover {
				color: $secondary-color;
			}

			&:active,
			&:focus {
				color: $primary-color;
			}
		}

		li.is-active {
			> a {
				color: $secondary-color;
			}
		}
	}

	// Desktop styles
	#desktop-menu {
		display: none;

		@include breakpoint(1200px) {
			display: flex;
		}
		ul li.mobile-home {
			display: none;
		}
	}

	//mobile logo placement
	.logo {
		position: absolute;
		top: 1rem;
		left: 50%;
		transform: translateX(-50%);

		@include breakpoint(medium) {
			position: static;
		}
	}

	// Hide the mobile button on desktop
	[data-bm-toggle] {
		display: flex;
		border: none;
		background: unset;

		@include breakpoint(1200px) {
			display: none;
		}
	}
}

[data-bm-toggle] {
	display: flex;
	flex-flow: column nowrap;
	padding-top: 1rem;
	.toggle-line {
		display: block;
		background-color: $secondary-color;
		align-self: flex-end;
		margin: 0.2rem;
		height: 4px;
		width: 40px;

		&:nth-child(2) {
			width: 30px;
		}
	}
}

#mobile-nav-popup {
	z-index: 10000;
	color: white;
	background: $primary-color;
	overflow-y: auto;

	li {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	li.is-active {
		a {
			color: $secondary-color;
		}
	}

	li.menu-item-has-children {
		a {
			align-self: center;
		}

		ul {
			li {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}

		.sub-menu {
			background-color: $primary-color-light;
			margin: 0 auto;
		}
	}

	li {
		width: 100% !important;
		justify-content: center !important;
		flex-flow: column !important;
		a {
			color: white;
			text-align: center;
			flex-shrink: 0;
			align-self: center;
		}
	}

	[data-bm-popup-close] {
		position: absolute;
		top: 2rem;
		right: 2rem;

		display: flex;
		flex-flow: row nowrap;
		align-self: flex-end;
		width: 40px;
		height: 40px;
		border: none;
		background: unset;

		.toggle-line {
			position: absolute;
			background-color: white;
			margin: 0.2rem;
			height: 4px;
			width: 30px;

			&:first-child {
				transform: rotate(-45deg);
			}

			&:last-child {
				transform: rotate(45deg);
			}
		}

		&:hover,
		&:active,
		&:focus {
			border: none;
			outline: none;
			background: unset;
			.toggle-line {
				background-color: $secondary-color;
			}
		}
	}
}

// Extras
.header-nav {
	margin-top: 0;
	padding-top: 0;
}

header.header-nav {
	.logo-container {
		padding-top: 0.5rem;
		@include mq(l) {
			padding-top: 0;
			padding-bottom: 1rem;
		}
	}

	a.nav-button {
		padding: 1rem 1rem;
		padding-top: 1.2rem;
		background: $secondary-color;
		align-self: flex-start;
		color: $primary-color;
		transition: all 0.3s ease;
		font-weight: 500;

		&:hover {
			color: $primary-color-dark;
			background: $secondary-color-dark;
		}

		.svg-pin {
			height: 40px;
			margin-right: 10px;
		}
	}

	[data-better-menu="vertical"] {
		a {
			&:hover {
				color: $secondary-color !important;
			}
		}
	}

	// Sticky header~~~
	&[data-s-active] {
		position: fixed;

		&:after {
			content: "";
			background: white;
			position: absolute;
			box-shadow: 3px 3px 5px rgba($gray, 0.3);
		}

		ul.menu li.menu-item a {
			@include mq(l) {
				color: $primary-color !important;
			}
		}

		transition: all 0.3s ease;

		.toggle-line:first-child {
			background-color: $gray !important;
		}

		.toggle-line:nth-child(2) {
			background-color: $gray;
		}
	}
}

ul.menu {
	margin-top: 0.5rem;
	margin-right: 1rem;
}

.header-top-bar {
	display: flex;
	flex-flow: column nowrap;
	background: $primary-color;

	.top-bar-inner-wrap {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 100%;
		width: $global-width;
		padding: 10px $padding;

		@include mq(m) {
			justify-content: flex-end;
		}

		a.fancy-btn:first-child {
			display: none;

			@include mq(m) {
				display: flex;
			}
		}

		a.fancy-btn {
			@extend .fluid-body-sm;
			display: flex;
			margin-left: 0.5rem;
			padding-left: 1rem;
			padding-right: 1.2rem;
			justify-content: center;
			align-items: center;
			vertical-align: center;

			&:after {
				display: none;
			}

			&:hover,
			&:active,
			&:focus {
				color: white;
				&:before {
					width: 100%;
					background-color: $secondary-color-dark;
				}
			}
		}
	}
}

.wpfront-message {
	font-weight: 500;
}

.svg-tooth {
	margin: 40px auto;
}

.popup-top-row {
	padding: 1rem;
}

.popup-buttons {
	display: flex;

	a {
		border-radius: 50px;
		background: $tertiary-color;
		padding: 8px !important;
		margin: 5px;
		transition: background 0.3s ease;
		svg {
			width: 26px;
			height: 26px;
			object-fit: contain;
			object-position: center center;
		}

		&:hover,
		&:active,
		&:focus {
			background: $secondary-color-dark;
		}

		&.secondary-color {
			background: $secondary-color;

			&:hover,
			&:active,
			&:focus {
				background: $secondary-color-dark;
			}
		}
	}
}


.country-selector {
	margin-left: .5rem;
	align-self: center;
}
