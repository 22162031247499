

.page-hero {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	max-width: 100%;
	padding-top: 100px;
	padding-bottom: $margin-lg;
	background-color: rgba($secondary-color-light, .25);

	.bg-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-color: rgba($white, .5);
		z-index: 0;
	}


	.hero-inner-wrap {
		width: 100%;
		max-width: $global-width;
		margin-left: auto;
		margin-right: auto;
		justify-self: stretch;
		align-self: stretch;
		padding: $padding;
		z-index: 1;
		h1 {
			color: $primary-color
		}
	}
}

.page-single-content {
	margin-top: -3rem;

	.s-inner-wrap {
		padding-top: $padding-md;
		padding-bottom: $padding-md;

		box-shadow: 5px 5px 15px rgba($black, .3);
		background: white;
		margin-bottom: 2rem;
	}
}
