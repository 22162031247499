.single-locations {
	main {
		position: relative;
		max-width: 100%;

		@include breakpoint(large, down) {
			margin: 0;
		}
	}
	img.featured-image {
		position: absolute;
		top: 0;
		right: 0;
		max-width: 660px;
		width: 55%;

		@include breakpoint(medium, down) {
			position: relative;
			top: auto;
			right: auto;
			max-width: 100%;
			width: 100%;
			margin-top: 1rem;
		}
	}
	.content {
		padding: var(--fluid-padding);
		width: 100%;
		max-width: 630px;
		transform: translateX(-50%);
		left: 50%;
		position: relative;

		@include breakpoint(1100px, up) {
			margin-left: -236px;
		}
		@include breakpoint(1100px, down) {
			margin: 0;
			transform: none;
			left: auto;
		}
	}
	section.dentists {
		background: $light-gray;
		padding: 5rem 0;
		margin-top: 2rem;
		.dentist-grid {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
		}
		h2 {
			width: 100%;
			text-align: center;
			@include fluid-prop(font-size, 18px, 35px);
			margin-bottom: 3rem;
		}
		.single-dentist {
			display: flex;
			flex-direction: column;
			box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.3);
			padding: 0.5rem;
			flex-wrap: wrap;
			margin: 1rem 0.5rem;
			justify-content: space-around;
			width: 120px;
			background-color: $white;

			@include breakpoint(medium, up) {
				width: 203px;
			}

			h3 {
				@include fluid-prop(font-size, 14px, 18px);
				margin-top: 0.5rem;
			}
			a {
				color: $secondary-color;
				font-weight: bold;
				text-decoration: none;
				@include fluid-prop(font-size, 12px, 14px);
			}
		}
	}

	section.visit-us {
		position: relative;
		margin-bottom: 1rem;

		img {
			width: 100%;
			height: auto;
		}
		h3 {
			position: absolute;
			color: $white;
			left: 50%;
			transform: translateX(-50%);
			bottom: 10%;
			width: 100%;
			text-align: center;
			@extend .fluid-h1;
		}
	}

	.location-slider-wrapper {
		--swiper-navigation-color: #{$secondary-color};

		.location-slide {
			padding-bottom: 70%;
			background-position: top center;
			background-size: cover;
			background-repeat: no-repeat;

			@include mq(l) {
				padding-bottom: 48%;
			}


			.inner-wrap {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 20px 20px;
				display: flex;
				justify-content: center;
				align-items: flex-end;

				@include mq(m) {
					padding: 80px 20px;
				}

				.slider-title {
					color: white;
					text-shadow: 2px 2px 4px rgba(black, .5);
					text-align: center;
				}
			}
		}
	}
}
