.body-controller {
	overflow-x: hidden;
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}

	main {
		max-width: $global-width;
		margin: 0 auto;
		width: 100%;
	}
}
