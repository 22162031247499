#mapContainer {
	height: 400px;
	width: 400px;

	a:hover {
		svg path {
			fill: #13b8c6;
		}
	}
}
.map-info {
	h4 {
		color: #26235f;
		margin: 0;
		font-size: 18px;
	}
	a {
		color: #13b8c6;
	}
}
