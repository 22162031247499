#page-404 {
	background-image: url("../images/404-floss.jpg");
	background-position: center right;
	background-size: auto 100%;
	max-width: 100%;
	width: 1400px;
	margin: 0 auto;
	.s-inner-wrap {
		width: 1000px;
	}
	.cell {
		display: flex;
		flex-flow: column nowrap;
		color: white;
		width: 30rem;
		max-width: 100%;

		h1 {
			margin-bottom: 1.5rem;
		}
		div {
			margin-bottom: 1.5rem;
		}
		a {
			margin-bottom: 1rem;
			align-self: flex-start;
			width: 14rem;
			max-width: 100%;
			padding-top: .5rem;
			padding-bottom: .5rem;
		}
	}
}
