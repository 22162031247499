

.landing-hero {
	box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, .25);
	.s-inner-wrap {
		padding-top: 150px;
		padding-bottom: 150px;
	}

	.hero-logo {
		width: 450px;
		max-width: 100%;
	}
	.c-grid {
		--grid-gap: 40px;
		margin: 0 auto;
		width: 100%;

		--grid-col-lg: 1.5fr 2fr;

		.cell {
			align-content: center;
			align-items: center;
			justify-content: center;
		}
	}
}


.landing-content {
	.s-inner-wrap {
		width: 1000px;
	}
	@include mq(m) {
		.fancy-btn {
			align-self: flex-start;
		}
	}
	.c-grid {
		--grid-gap: 60px;
	}

	.fancy-btn {
		margin-top: 2rem;
	}

	.info-wrap {
		width: 100%;
		max-width: 400px;
		margin: 0 auto;

		h2 {
			margin-bottom: 1rem;
		}

		h6 {
			color: $secondary-color;
		}

		.sub-text {
			font-weight: 500;
		}
	}

	.image-wrap {
		max-width: 100%;
		width: 125px;
		margin: auto;

		@include mq(m) {
			width: 225px;
		}

		@include mq(l) {
			width: 275px;
		}
	}
}
