.page-template-forms {
	main {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		max-width: 100%;
		width: 100%;
		min-height: 100vh;
		margin-top: 20px;
		@include breakpoint(900px) {
			flex-flow: row nowrap;
		}
	}
	section.form {
		background: $secondary-color;
		max-width: 960px;
		width: 50%;
		margin-left: auto;
		padding: 3rem 2rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
		min-height: 100vh;
		height: auto;

		@include breakpoint(large, down) {
			height: auto;
			position: relative;
			top: auto;
			right: auto;
			max-width: 100%;
			width: 100%;
			margin-top: 1rem;
		}

		h2 {
			color: $white;
			margin-bottom: 2rem;
		}

		input,
		textarea,
		select {
			border-radius: 100px;
			padding: 0.25rem 1rem !important;
			border: none;
			margin-top: 0 !important;
		}

		textarea {
			border-radius: 20px;
		}
		label {
			color: #ffffff;
		}
		.gfield_label {
			display: none;
		}
		.ginput_container {
			margin-top: 0 !important;
		}
		.name_first,
		.name_last {
			input {
				margin-bottom: 0 !important;
			}
		}
		.gform_wrapper ul.gform_fields li.gfield {
			margin-bottom: 1.75rem !important;
		}
		.gfield_consent_description {
			color: #ffffff;
		}
	}
	section.content {
		padding: var(--fluid-padding);
		width: 100%;
		max-width: 600px;
		position: relative;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: flex-start;
		max-width: 630px;
		transform: translateX(-50%);
		left: 50%;
		position: relative;
		margin-left: -386px;
		@include breakpoint(1399px, down) {
			margin-left: calc(-50% + 315px);
		}
		@include breakpoint(1200px, down) {
			margin: 0;
			transform: none;
			left: auto;
		}

		figure {
			max-width: 100%;
			img {
				object-fit: contain;
			}
		}

		@include breakpoint(large, down) {
			margin: 0;
			transform: none;
			left: auto;
		}
	}
}

.ginput_container_select:after {
	content: " ";
	background: url(../images/down-caret.svg) center center no-repeat;
	color: #333;
	right: 11px;
	top: 10px;
	height: 20px;
	width: 20px;
	padding: 0 0 2px;
	position: absolute;
}

.ginput_container_select {
	width: 100% !important;
	position: relative;
	margin-top: 12px;
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		/* Add some styling */
		cursor: pointer;

		display: block;
		width: 100%;
		background-color: #ffffff;
		background-image: none;
		border: 1px solid #cccccc;
	}
}

.gform_footer {
	img {
		display: none !important;
	}
}
