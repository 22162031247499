.post-type-archive-locations {
	main {
		position: relative;
		max-width: 100%;

		@include breakpoint(large, down) {
			margin: 0;
		}
	}
	#mapContainer {
		position: relative;
		right: 0;
		top: 0;
		height: 700px;
		width: 100%;
		@include breakpoint(1200px, up) {
			max-width: 725px;
			width: calc(40% - 1rem);
			position: absolute;
		}
	}
	.listings {
		padding: var(--fluid-padding);
		width: 100%;
		max-width: 729px;
		transform: translateX(-50%);
		left: 50%;
		position: relative;
		margin-left: -335px;
		@include breakpoint(1399px, down) {
			margin-left: calc(-50% + 370px);
		}
		@include breakpoint(1200px, down) {
			margin: 0;
			transform: none;
			left: auto;
		}
	}
	h1 {
		margin-bottom: 2rem;
	}

	.location-card {
		display: flex;
		flex-direction: row;
		box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.3);
		padding: 1rem;
		margin-top: 1rem;
		flex-wrap: wrap;
		background-color: $white;

		@include breakpoint(640px, up) {
			flex-wrap: nowrap;
		}

		img {
			align-self: flex-start;
			order: 1;

			@include breakpoint(640px, down) {
				width: 100%;
				order: 2;
			}
		}
		p {
			@include fluid-prop(font-size, 12px, 14px);
			margin: 0;
		}

		.content {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
			order: 2;

			@include breakpoint(640px, down) {
				width: 100%;
				order: 1;
			}

			a.button {
				width: 100%;
				display: inline-block;
				margin-bottom: 1rem;
			}
		}
		h2 {
			@include fluid-prop(font-size, 24px, 35px);
			width: 100%;
		}

		.left {
			display: flex;
			flex-direction: column;
			width: 50%;
			margin-bottom: 1rem;
			a {
				color: $secondary-color;
				font-weight: bold;
				margin-bottom: 0.5rem;
				@include fluid-prop(font-size, 14px, 16px);
			}
		}
		.right {
			margin-bottom: 1rem;

			@include breakpoint(large, up) {
				width: 50%;
			}
			h3 {
				@include fluid-prop(font-size, 14px, 16px);
			}
		}
	}
}

.location-image-wrap {
	position: relative;
	width: 250px;
	height: 250px;
	max-width: 100%;
	margin: 0 auto;
	flex-shrink: 0;
	order: 2;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		padding: $padding;
	}
}
