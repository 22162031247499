.dentist-card {
	display: flex;
	flex-flow: column;
	padding: 10px;
	color: $secondary-color;
	background-color: $white;
	font-weight: 500;
	box-shadow: 3px 3px 8px rgba($black, .4);

	&:hover, &:active, &:focus {
		color: $secondary-color-dark;
	}
	.dentist-name {
		font-weight: 600;
		color: $primary-color;
		padding-top: .5rem;
		padding-bottom: 1rem;
	}

	.dentist-image {
	}
}


.page-archive-dentists {

	h1 {
		max-width: 200px;
	}

	.s-inner-wrap {
		padding-top: 0;
		margin-top: -50px;
	}
	.c-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
		@include mq(m) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include mq(l) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
