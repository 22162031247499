/**
 * Buttons
 **/

.fancy-btn,
.gform_button {
	display: inline-block;
	position: relative;
	padding: 0.3em 2.5em;
	font-weight: 500;
	color: white;
	transition: color .3s ease, background .3s ease;
	text-decoration: none;
	text-align: center;
	border-radius: 100px;
	z-index: 1;

	&:before {
		content: '';
		background: $tertiary-color;
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		border-radius: 50px;
		transition: width .3s ease, background .3s ease;
	}

	&:after {
		content: url("../images/button-icons/icon-arrow.svg");
		position: absolute;
		z-index: 1;
		top: 50%;
		right: 7px;
		bottom: 0;
		display: flex;
		height: 28px;
		width: 28px;
		justify-content: center;
		align-items: center;
		opacity: 0;
		padding: .1rem;
		transition: width .3s ease, background .3s ease, opacity .3s ease;
		transform: translateY(-50%);
	}

	// hover states
	&:hover, &:focus, &:active {
		color: $black;

		&:before {
			width: 42px;
			background: $primary-color-dark;
		}

		&:after {
			opacity: 1;
		}
	}

	// active state - click
	&:active {
		color: white;

		&:before {
			background: darken($primary-color-dark, 10%);
			width: 100%;
		}
	}
}

.btn-cyan {

	&:before {
		background: $secondary-color;
	}
}

.btn-blue {
	&:before {
		background: $primary-color;
	}
}

.btn-gray,
.gform_button {
	background: $tertiary-color;
	cursor: pointer;

	&:hover, &:focus, &:active {
		background: $quaternary-color;
		color: white;
	}
}


// icon modifiers

.btn-icon-pin {
	&:after {
		content: url('../images/button-icons/icon-pin.svg');
	}
}

.btn-icon-mail {
	&:after {
		content: url('../images/button-icons/icon-mail.svg');
	}
}

.btn-icon-calendar {
	&:after {
		content: url('../images/button-icons/icon-calendar.svg');
	}
}

.btn-icon-money {
	&:after {
		content: url('../images/button-icons/icon-money.svg');
	}
}

.wp-block-button {
	.wp-block-button__link {
		background: unset;
		@extend .fluid-body;
		@extend .fancy-btn;
	}
}


.wp-block-image {
	img {
		height: auto !important;
	}
}
