.footer {
	display: flex;
	flex-flow: column;
	background-color: $primary-color;

	.footer-inner-wrap {
		display: flex;
		flex-flow: column;
		max-width: 225px;
		width: 100%;
		padding: $padding;
		margin: 0 auto;
		align-self: stretch;

		@include mq(m) {
			max-width: $global-width;
			flex-flow: row wrap;
			justify-content: space-between;
		}
	}
	.logo-cell {
		img {
			width: 200px;
		}
	}

	.social-cell {
		display: flex;
		flex-flow: row nowrap;
		align-content: center;
		justify-content: center;
		margin-top: 1.5rem;

		@include mq(l) {
			margin-right: auto;
			margin-left: 3rem;
		}

		a {
			align-self: center;
		}
		svg {
			margin: .2rem;
			width: 30px;
			path {
				color: white;
			}

			&:hover, &:active, &:focus {
				path {
					color: $secondary-color;
				}
			}
		}
	}



	.link-cell {
		display: flex;
		flex-flow: column nowrap;
		align-content: center;
		justify-content: center;
		margin-top: 1rem;
		width: 100%;

		a {
			@extend .fluid-body-sm;
		}

		@include mq(m) {
			flex-flow: row nowrap;

		}

		@include mq(l) {
			width: auto;
			margin-bottom: 1rem;
		}

	}
	.footer-link {
		color: white;
		padding: .2rem .4rem;
		text-align: center;
		align-self: center;

		&:hover, &:active, &:focus {
			color: $secondary-color;
		}

		@include mq(m) {
			text-align: left;
		}
	}
}

.gm-style-mtc, .gm-svpc {
	display: none;
}
